

import './App.css';
import Footer from './Footer';
import Home from './Home';

function App() {
  
  return (
    <div>
      <Home/>
      <Footer/>
    </div>
  );
}

export default App;
